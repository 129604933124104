import React from "react";
import { graphql, StaticQuery } from "gatsby";
import Img, { FixedObject } from "gatsby-image";

interface Props {
  alt: string;
}

interface StaticQueryData {
  file: {
    childImageSharp: {
      fixed: FixedObject;
    };
  };
}

function WorkoutBuilderImage({ alt }: Props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          file(relativePath: { eq: "workout-builder.png" }) {
            childImageSharp {
              fixed(width: 260, height: 180) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      `}
      render={(data: StaticQueryData) => {
        return (
          <Img
            imgStyle={{ width: "260px", height: "180px" }}
            fixed={data.file.childImageSharp.fixed}
            alt={alt}
          />
        );
      }}
    />
  );
}

export default WorkoutBuilderImage;
