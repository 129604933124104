import React, { useState, useEffect } from "react";
import ReactGA from "react-ga";
import { render } from "react-dom";
import { SkillProvider, SkillTreeGroup, SkillTree } from "beautiful-skill-tree";
import StatsCard from "../components/StatsCard";
import Confetti from "../components/Confetti";
import { caliSkillsTreeTheme } from "../constants";
import tourData, { premiumData } from "../data/tour/index";
import { useEffectOnce } from "react-use";
import Loader from "./Loader";
import arrowStyles from "../styles/Arrow.module.css";

function Arrow() {
  return (
    <div id="arrow" className={arrowStyles.Arrow__container}>
      <p className={arrowStyles.Arrow__text}>Hover/Hold here</p>
      <div className={arrowStyles.Arrow}>
        <div className={arrowStyles.Arrow__curve} />
        <div className={arrowStyles.Arrow__point} />
      </div>
    </div>
  );
}

function appendArrowToTree() {
  if (document.getElementById("arrow")) {
    return null;
  }

  const topSkill = document.querySelector('[data-testid="skills"]');

  const div = document.createElement("div");
  topSkill?.appendChild(div);

  render(<Arrow />, div); // this is weird, I know.
}

function TourSkillTree() {
  const [componentHasMounted, setComponentIsMountedState] = useState(false);

  useEffectOnce(() => {
    setComponentIsMountedState(true);
  });

  useEffect(() => {
    appendArrowToTree();
  });

  if (!componentHasMounted) {
    return <Loader />;
  }

  return (
    <SkillProvider>
      <SkillTreeGroup theme={caliSkillsTreeTheme}>
        {({ skillCount, selectedSkillCount }) => {
          const totalSkillCount = skillCount.required;

          const totalSelectedSkillCount = selectedSkillCount.required;

          const allSkillsSelected =
            totalSkillCount === totalSelectedSkillCount && totalSkillCount > 0;

          return (
            <React.Fragment>
              <Confetti active={allSkillsSelected} />
              <StatsCard
                skillCount={totalSkillCount}
                selectedSkillCount={totalSelectedSkillCount}
              />
              <SkillTree
                handleNodeSelect={() => {
                  if (totalSelectedSkillCount === 0) return;

                  ReactGA.event({
                    category: "Tour",
                    action: "User completed a tour step",
                    label: `User has completed ${totalSelectedSkillCount} of ${totalSkillCount} steps of the tour`
                  });
                }}
                treeId="tour"
                title="Free Features"
                data={tourData}
              />
              <SkillTree
                handleNodeSelect={() => {
                  if (totalSelectedSkillCount === 0) return;

                  ReactGA.event({
                    category: "Tour",
                    action: "User completed a premium tour step",
                    label: `User has completed ${totalSelectedSkillCount} of ${totalSkillCount} steps of the tour`
                  });
                }}
                treeId="premium-tour"
                title="Premium Features"
                data={premiumData}
              />
            </React.Fragment>
          );
        }}
      </SkillTreeGroup>
    </SkillProvider>
  );
}

export default TourSkillTree;
