import React, { useState, useEffect, useRef } from "react";
import ReactGA from "react-ga";
import { confetti } from "dom-confetti";
import { useMouse } from "react-use";
import usePrevious from "../hooks/usePrevious";

type Props = {
  active: boolean;
};

function Confetti({ active }: Props) {
  const ref = React.useRef(null);
  const { docX, docY } = useMouse(ref);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    setPosition({ x: docX, y: docY });

    if (active) {
      ReactGA.event({
        action: "All skills completed",
        category: "Application",
        label: "All skills completed"
      });
    }

    // eslint-disable-next-line
  }, [active]);

  return (
    <div
      ref={ref}
      style={{
        position: "absolute",
        top: position.y,
        left: position.x,
        zIndex: 1
      }}
    >
      <ConfettiFlakes active={active} />
    </div>
  );
}

// Make every breakfast feel special...
function ConfettiFlakes({ active }: { active: boolean }) {
  const confettiRef = useRef(null);
  const prevActive = usePrevious(active);

  useEffect(() => {
    if (active && !prevActive) {
      confetti(confettiRef.current!);
    }
  }, [active, prevActive]);

  return <div ref={confettiRef} style={{ position: "relative" }} />;
}

export default Confetti;
