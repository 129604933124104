import React from "react";
import SEO from "../components/SEO";
import TextPageLayout from "../components/layouts/TextPageLayout";
import TourSkillTree from "../components/TourSkillTree";
import { Link } from "gatsby";

function Tour() {
  return (
    <TextPageLayout title="Cali Skills Tour">
      <SEO
        path="/tour/"
        title="Cali Skills Tour"
        description="Check out all of the incredible features that Cali Skills has to offer."
      />
      <div style={{ maxWidth: "650px", margin: "0 auto" }}>
        <p>
          Welcome to the Cali Skills Tour. Let us take you through
          Cali Skills' growing list of features designed to help you
          achieve your fitness goals.
        </p>
        <p>
          Or for our premium subscribers, take the{" "}
          <a href="#premium-tour">premium features</a> tour and learn you how
          you can take you calisthenics to the next level.
        </p>
        <p>
          Want to upgrade? Just head to our{" "}
          <Link to="/pricing">pricing page</Link>
        </p>
        <TourSkillTree />
      </div>
    </TextPageLayout>
  );
}

export default Tour;
