import React from "react";
import { SkillType } from "beautiful-skill-tree";
// @ts-ignore
import SelectingASkill from "./selecting-a-skill.webp";
// @ts-ignore
import AdvancedWorkoutBuilderWebp from "./advanced-workout-builder.webp";
// @ts-ignore
import ProgressDashboardWebP from "./progress-dashboard.webp";
// @ts-ignore
import RadarChartWebP from "./radar-chart.webp";
// @ts-ignore
import LastMinuteChangesWebp from "./last-minute-changes.webp";
import WorkoutBuilderImage from "../../components/images/WorkoutBuilderImage";
import { Link } from "gatsby";
import "../../styles/Tour.css";

function SkillsTooltip() {
  return (
    <div className="Tour_Tooltip">
      <p>
        Start your calisthenics journey here. Hover/Hold over a skill to see its
        description and video.
      </p>
      <p>
        Achieved your first rep? Click/Tap the skill to update your skill tree.
        Once a skill is selected, the skill below will unlock, ready for you to
        achieve.
      </p>
      <p>Selecting skill will look like this:</p>
      <img
        src={SelectingASkill}
        alt="selecting a skill."
        style={{ width: "100%" }}
      />
      <p>
        You need to <Link to="/login/">Sign up</Link> to track your journey.
      </p>
    </div>
  );
}

function SkillTreeTooltip() {
  return (
    <div className="Tour_Tooltip">
      <p>
        Progression is at the heart of calisthenics. Every skill is part of a
        skill tree, with each tree focusing on a milestone skill, like the Pull
        Up.
      </p>
      <p>
        Each skill leads into the next one. For example, an Assisted Squat leads
        into a Parallel Squat, which leads into the Full Squat.
      </p>
    </div>
  );
}

function FocusTreeTooltip() {
  return (
    <div className="Tour_Tooltip">
      <p>Never lost sight of what you want to achieve.</p>
      <p>
        You can Focus on a Skill Tree by clicking its{" "}
        <span style={{ color: "#f54400" }}>☆</span>. Your Focused Skill Tree
        will then always be waiting for you as the first progression on the
        Skill Tree page.
      </p>
    </div>
  );
}

function ProgressTrackingTooltip() {
  return (
    <div className="Tour_Tooltip">
      <p>Cali Skills is with you every step of the way.</p>
      <p>
        You can store your max rep count, or max hold time for each exercise
        using the handle input, like the one below.
      </p>
      <div className="UpdateProgressForm">
        <label
          className="UpdateProgressForm__label"
          htmlFor="progress-tracking"
        >
          max rep count:
        </label>
        <div style={{ display: "flex" }}>
          <input
            className="UpdateProgressForm__input"
            type="number"
            id="progress-tracking"
            name="max-progress"
            defaultValue="0"
          />
          <button type="submit" className="UpdateProgressForm__button">
            save
          </button>
        </div>
      </div>
      <p>
        Because holding your L-Sit for just one second longer is an achievement.
      </p>
    </div>
  );
}

function ExercisesTooltip() {
  return (
    <div className="Tour_Tooltip">
      <p>Combine form and function, with our growing exercise library.</p>
      <p>
        You can find videos, difficulty levels, progressions, regressions, and
        more for each exercise. Cali Skills has ambitions to provide a
        free world-class exercise library.
      </p>
    </div>
  );
}

function WorkoutsTooltip() {
  return (
    <div className="Tour_Tooltip">
      <p>
        Whether you want to lose weight, build strength, or improve your Pull
        Ups, we have a workout just for you.
      </p>
      <p>Generate a workout in one of three formats:</p>
      <ul style={{ listStyle: "circle", marginLeft: "16px" }}>
        <li>Reddit's Recommended Routine</li>
        <li>First Pull Ups and Beyond</li>
        <li>Improve Your Isometric Holds</li>
      </ul>
    </div>
  );
}

function WorkoutBuilder() {
  return (
    <div className="Tour_Tooltip">
      <p>
        Want more freedom with your workouts? With workout builder, you can
        choose the progressions you want to work on.
      </p>
      <WorkoutBuilderImage alt="the workout generator." />
      <p>Pick the progressions, and we'll pick the exercises.</p>
    </div>
  );
}

function WorkoutGeneratorTooltip() {
  return (
    <div className="Tour_Tooltip">
      <p>
        Don't waste time working out your routine. Just spend time working out.
      </p>
      <p>
        Once you've built your workout, you can perform it in Calisthenics
        Skills. We'll pick the exercises based on your skill tree progress. And
        if you beat your previous max reps, we'll update your skill tree.
      </p>
      <p>It all comes full circle.</p>
    </div>
  );
}

function YoureDoneTooltip() {
  return (
    <div className="Tour_Tooltip">
      <p>You've reached the end of the tour!</p>
      <p>
        <Link to="/login/">Sign up</Link> to Cali Skills and start
        tracking your progress, completing your skill trees, and generating your
        own workouts.
      </p>
    </div>
  );
}

const data: SkillType[] = [
  {
    id: "skills",
    title: "Skills",
    tooltip: {
      content: <SkillsTooltip />
    },
    children: [
      {
        id: "skill-tree",
        title: "Skill Tree",
        tooltip: {
          content: <SkillTreeTooltip />
        },

        children: [
          {
            id: "focus",
            title: "Focus",
            tooltip: {
              content: <FocusTreeTooltip />
            },
            children: [
              {
                id: "progress-tracking",
                title: "Progress Tracking",
                tooltip: {
                  content: <ProgressTrackingTooltip />
                },
                children: [
                  {
                    id: "exercises",
                    title: "Exercises",
                    tooltip: {
                      content: <ExercisesTooltip />
                    },
                    children: []
                  },
                  {
                    id: "workouts",
                    title: "Workouts",
                    tooltip: {
                      content: <WorkoutsTooltip />
                    },
                    children: [
                      {
                        id: "workout-builder",
                        title: "Workout Builder",
                        tooltip: {
                          content: <WorkoutBuilder />
                        },
                        children: [
                          {
                            id: "workout-generator",
                            title: "Workout Generator",
                            tooltip: {
                              content: <WorkoutGeneratorTooltip />
                            },
                            children: [
                              {
                                id: "you're-done",
                                title: "You're Done!",
                                color: "alternative",
                                tooltip: {
                                  content: <YoureDoneTooltip />
                                },
                                children: []
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];

function MoreProgressionsTooltip() {
  return (
    <div className="Tour_Tooltip">
      <p>
        Great, you've achieved your Pull Up, and you've mastered your
        Handstands, but where do you go now? With our premium progressions, you
        can learn even more jaw-dropping exercises.
      </p>
      <p>
        Premium exclusive progressions include; Handstand Press, One-Armed Pull
        Up, One-Armed Push Up.
      </p>
    </div>
  );
}

function AdvancedWorkoutTooltip() {
  return (
    <div className="Tour_Tooltip">
      <p>
        Want more control over your workouts? Our Advanced Workout Builder gives
        you more flexibility about how you perform your bodyweight workouts.
      </p>
      <p>
        You can build your own <Link to="/workouts/custom-workouts">here</Link>.
      </p>
    </div>
  );
}

function AdjustSetExercises() {
  return (
    <div className="Tour_Tooltip">
      <p>You can add more exercises to each set.</p>
      <img
        src={AdvancedWorkoutBuilderWebp}
        alt="advanced workout builder."
        style={{ width: "100%" }}
      />
    </div>
  );
}

function LastMinuteChanges() {
  return (
    <div className="Tour_Tooltip">
      <p>
        You can make a couple of tweaks to the workout we've generated for you.
      </p>
      <p>Just tap the edit button and change the exercise.</p>
      <img
        src={LastMinuteChangesWebp}
        alt="advanced workout builder."
        style={{ width: "100%" }}
      />
    </div>
  );
}

function ProgressDashboardTooltip() {
  return (
    <div className="Tour_Tooltip">
      <p>
        How's your progress coming along? Use the dashboard to track your how
        much you've improved over the last few months.
      </p>
      <p>
        View your progress <Link to="/profile">here</Link>.
      </p>
      <img
        src={ProgressDashboardWebP}
        alt="progress dashboard."
        style={{ width: "100%" }}
      />
    </div>
  );
}

function RadarChartTooltip() {
  return (
    <div className="Tour_Tooltip">
      <p>
        Want to see how close you are to completing your progressions? Our sleek
        Radar Chart shows you how far along each progression you are.
      </p>
      <p>
        View your progress <Link to="/profile">here</Link>.
      </p>
      <img src={RadarChartWebP} alt="radar chart." style={{ width: "100%" }} />
    </div>
  );
}

export const premiumData: SkillType[] = [
  {
    id: "more-progressions",
    title: "More Progressions",
    tooltip: {
      content: <MoreProgressionsTooltip />
    },
    children: []
  },
  {
    id: "advanced-workout-builder",
    title: "Advanced Workout Builder",
    tooltip: {
      content: <AdvancedWorkoutTooltip />
    },
    children: [
      {
        id: "add-exercises-to-set",
        title: "Add Exercises to Set",
        tooltip: {
          content: <AdjustSetExercises />
        },
        children: []
      },
      {
        id: "make-last-minute-change",
        title: "Make Last Minute Changes",
        tooltip: {
          content: <LastMinuteChanges />
        },
        children: []
      }
    ]
  },
  {
    id: "progress-dashboard",
    title: "Progress Dashboard",
    tooltip: {
      content: <ProgressDashboardTooltip />
    },
    children: [
      {
        id: "radar-chart",
        title: "Radar Chart",
        tooltip: {
          content: <RadarChartTooltip />
        },
        children: []
      }
    ]
  }
];

export default data;
